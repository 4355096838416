.currentservicecontainer {
    display: flex;
    flex-direction: row;
/*    align-items: flex-start;*/
/*padding-top: 10px;*/
/*    text-align: center;*/
@media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
}
}


.servicephonepicturecontainer {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 25px;
    @media screen and (max-width: 800px) {
        width: 100%;
        justify-content: center;
        padding-right: 0px;
    }
}

.servicephonepicture {
    max-width: auto; /* Изображение не выходит за границы */
    /*object-fit: contain;*/
    max-height: 500px;
    @media screen and (max-width: 800px) {
        padding-top: 0px;
    }
}

.servicesItem {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: flex-start;*/
    /*justify-content: flex-start;*/
    /*text-align: center;*/
}

.serviceItemHeader1 {
display: none;

@media screen and (max-width: 800px) {
    display: block;
    margin-bottom: 30px;
    color: #337ab7;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
}
}

.serviceItemHeader2 {
    margin-bottom: 30px;
    color: #337ab7;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
@media screen and (max-width: 800px) {
    display: none;
}
}