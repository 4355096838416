@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 110%;
  }
}

.active {
  color: #337ab7 !important;
}

.logo {
  width: 25%;
}

.noPad {
  padding: 0 !important;
  margin: 0 !important;
}

.logoDiv {
  background-image: url("./img/smsretailblue.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
  margin: 0px;
  width: 270px;
  height: 60px;
}

.menu {
  width: 55%;
  margin: auto;
border: none;
@media screen and (max-width: 1024px) {
  margin: 0;
  width: auto;
}
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
@media screen and (max-width: 1100px) {
    flex-direction: column;
    margin-bottom: 10px;
}
}

@media screen and (max-width: 1100px) {
  .header {
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.menuitems {
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
@media screen and (max-width: 600px) {
  flex-wrap: wrap;
}
}

.contacts {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 25px;
  border: 0;
  padding-right: 25px;
@media screen and (max-width: 1024px) {
  justify-content: center;
}
}

.header-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contactline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
@media screen and (max-width: 600px) {
  font-size: 8px;
}
}

.contactlinephone {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
@media screen and (max-width: 600px) {
  font-size: 8px;
}

}


.nowrap {
  white-space: nowrap
}

.newstring {
display: none;
@media screen and (max-width: 600px) {
  display: block;
  width: 100%;
}

}

.newstring1 {
  width: 50px;

}

.menuitemsMobile {
  display: flex;
  flex-direction: row;
  gap: 20px;
@media screen and (max-width: 1024px) {
  justify-content: center;
}
@media screen and (max-width: 600px) {
  flex-wrap: no-wrap;
}
}

.menuitemsMobileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
margin-bottom: 0px;
@media screen and (max-width: 600px) {
  flex-direction: column;
}
}

.itemContainer {

}

.itemContainer > a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 10px 0;
  color: #777;
}

.itemContainer > a:hover:after {
  width: 100%;
}
.itemContainer.active > a,
.itemContainer.active > a:hover,
.itemContainer.active > a:focus {
  background-color: transparent;
}
.itemContainer.active > a:after,
.itemContainer.active > a:hover:after,
.itemContainer.active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}

.logoMobile {
  margin-bottom: 0;
  margin-right: 20px;
@media screen and (max-width: 600px) {
  margin-bottom: 10px;
  margin-right: 0px;
}
}

.mainPage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

@media screen and (max-width: 1024px) {
  flex-direction: column;
  align-items: center;
}
}

.send-button {
  border-radius: 5px;
}

.mainRow {
  color: #337ab7;
}

.servicesInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  font-size: 18px;
  font-style: normal;
  line-height: 40px;
  padding-top: 0px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 50px;

  @media screen and (max-width: 800px) {
    /*padding-top: 0px;*/
    /*padding-bottom: 35px;*/
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}


.faqInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  font-size: 24px;
  font-style: normal;
  line-height: 40px;
  padding: 50px;
}

.faqItem {
  width: 100%;
  border-top: 1px solid #777777;
  padding-top: 30px;
}

.chevron {
  min-width: 30px;
}

.chevronRotate {
  transform: rotate(180deg);
  transition: width 0.2s !important;
  min-width: 30px;
}

.form-input {
  border: 2px solid #ccc;
  text-align: center;
  font-size: 16px;
}

.faqQuestionLine {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 18px;

  font-weight: 500;
}

.faqAnswerLine {
  font-size: 16px;
  padding-top: 30px;
  font-weight: 400;
}

.servicesIcon {
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.servicePage {
  background: url(./img/logowithoutletters.png) repeat;
  padding-top: 50px;
}

.contact-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactitem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactlink {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.faqPage {
  background: url(./img/logowithoutletters.png) repeat;
  padding-top: 50px;
}

.aboutPage {
  background: url(./img/logowithoutletters.png) repeat;
  padding-top: 50px;
}

.aboutInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  font-size: 24px;
  font-style: normal;
  line-height: 40px;
  padding: 50px;
}

.aboutItem {
  width: 100%;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.aboutYear {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 18px;

  font-weight: 500;
}

.aboutText {
  font-size: 16px;
  padding-top: 30px;
  font-weight: 400;
}

.aboutDivider {
  background: url(./img/logowithoutletters.png);
  padding-top: 50px;
  width: 50px;
  height: 50px;
}

.aboutH {
  margin-bottom: 30px;
  color: #337ab7;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
}

.formbuttonsend {
  background: #337ab7;
}