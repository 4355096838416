.pricePage {
    background: url(../../img/logowithoutletters.png) repeat;
    padding-top: 50px;
    padding-bottom: 50px;
    @media screen and (max-width: 1024px) {

    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
        width: auto;
    }
}

.pricesItems {
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
@media screen and (max-width: 1024px) {
    padding-left: 30px;
    padding-right: 30px;
}
@media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
    padding: 0;
    margin-left: 50px;
    margin-right: 50px;
}
}


.countryItems {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-bottom: 30px;
    gap: 10px;
    @media screen and (max-width: 1024px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media screen and (max-width: 750px) {
        padding: 0;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: 10px;
        margin-left: 50px;
        margin-right: 50px;
    }
}

.countryItem {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #fff;
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px #d3dae1;
    -webkit-box-shadow: inset 0 0 0 1px #d3dae1;
    -moz-box-shadow: inset 0 0 0 1px #d3dae1;
    color: #0f151a;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    text-transform: none;

    background: #fff;
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px #d3dae1;
    -webkit-box-shadow: inset 0 0 0 1px #d3dae1;
    -moz-box-shadow: inset 0 0 0 1px #d3dae1;
    color: #0f151a;
    font-family: 'Arial';
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
    padding: 5px 20px;
    text-transform: none;
    text-underline-offset: 4px;
    vertical-align: inherit;
    text-align: center;
    cursor: pointer;
}

.selectedCountryItem {
    background: #337ab7;

    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    box-shadow: inset 0 0 0 1px #d3dae1;
    -webkit-box-shadow: inset 0 0 0 1px #d3dae1;
    -moz-box-shadow: inset 0 0 0 1px #d3dae1;
    color: white;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    text-transform: none;
    text-align: center;

    border-radius: 5px;
    box-shadow: inset 0 0 0 1px #d3dae1;
    -webkit-box-shadow: inset 0 0 0 1px #d3dae1;
    -moz-box-shadow: inset 0 0 0 1px #d3dae1;
    font-family: 'Arial';
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
    padding: 5px 20px;
    text-transform: none;
    text-underline-offset: 4px;
    vertical-align: inherit;
}

.priceText {
    margin-bottom: 30px;
    color: #337ab7;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
}

.faqText {
    margin-bottom: 30px;
    color: #337ab7;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
}

.priceFormat {
    margin-bottom: 30px;
    color: #337ab7;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
}

.priceInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 40px;
    padding-top: 40px;
    justify-content: center;
    gap: 30px;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    height: 40px;
    line-height: 40px;
}

.priceInfoItem {
    width: auto;
    text-align: center;

}

.priceInfoEmpty {
    width: auto;
    text-align: center;
@media screen and (max-width: 600px) {
    padding-top: 30px;
    padding-bottom: 30px;
}
}


.priceItem {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #fff;
    border-radius: 0px;
    box-shadow: inset 0 0 0 1px #d3dae1;
    -webkit-box-shadow: inset 0 0 0 1px #d3dae1;
    -moz-box-shadow: inset 0 0 0 1px #d3dae1;
    color: #0f151a;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    text-transform: none;

    background: #fff;
    border-radius: 0px;
    box-shadow: inset 0 0 0 1px #d3dae1;
    -webkit-box-shadow: inset 0 0 0 1px #d3dae1;
    -moz-box-shadow: inset 0 0 0 1px #d3dae1;
    color: #0f151a;
    font-family: 'Arial';
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
    padding: 5px 20px;
    text-transform: none;
    text-underline-offset: 4px;
    vertical-align: inherit;
    text-align: center;
    cursor: pointer;
    border-width: 0;
    border-style: none;
    border-color: transparent;
}

.selectedPriceItem {
    background: #337ab7;

    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0px;
    box-shadow: inset 0 0 0 1px #d3dae1;
    -webkit-box-shadow: inset 0 0 0 1px #d3dae1;
    -moz-box-shadow: inset 0 0 0 1px #d3dae1;
    color: white;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    text-transform: none;
    text-align: center;

    border-radius: 0px;
    box-shadow: inset 0 0 0 1px #d3dae1;
    -webkit-box-shadow: inset 0 0 0 1px #d3dae1;
    -moz-box-shadow: inset 0 0 0 1px #d3dae1;
    font-family: 'Arial';
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
    padding: 5px 20px;
    text-transform: none;
    text-underline-offset: 4px;
    vertical-align: inherit;
}

.priceRange {
    margin-left: 50px;
    margin-right: 50px;
}

.priceMarks {
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    justify-content: space-between;
    color: #337ab7;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
}


.thumb {
    background: url('../../../public/img/hey1.jpg');
    border: none !important;
}

.ptable {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
    border: 3px solid #ccc;
    margin-bottom: 30px;
    border-radius: 10px;
    border-collapse: collapse;
    background: #ffffff;
}

.prow {
    display: flex;
    flex-wrap: wrap;
}

.pcell {
    color: #337ab7;
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    text-align: center;
    width: 25%;
}

.pcellhead {
    color: #777777;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
}

.pcellmain {
    color: #337ab7;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
}

.pcellleft {
    color: #777777;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    word-wrap: break-word;
}

.pcellprice {
    white-space: nowrap;
}